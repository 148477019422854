import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { ExcelDataType, RefinedExcelDataType } from "../../../types/common";
import { DashboardLayout } from "../../../Layouts/Dashboard/Index";
import ExcelDataTable from "../../../Components/Table/ExcelDataTable";
import SelectFile from "../../../Components/Form/SelectFile";
import { UseAddParticipants } from "../../../hooks/useAddParticipants";
import LoadingState from "../../../Components/Loading";
import MyModal from "../../../Components/Modal";

import { useNavigate } from "react-router";
import CustomBreadcrumb from "../../../Components/custom-breadcrumb/CustmBreadcrumb";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFile] = useState<File | undefined>();
  const [excelData, setExcelData] = useState<ExcelDataType[]>();
  const [excelDataRefined, setExcelDataRefined] =
    useState<RefinedExcelDataType[]>();

  const selectOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && setSelectedFile(e.target.files[0]);
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (selectedFiles) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const data = e.target?.result;
        if (data) {
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);

          // Refine the JSON data
          const refineJson = json.map((item: any, idx) => ({
            id: item.Number,
            code: item.Number,
            name: item.Name,
            email: item.Email || "",
            phone: item.Phone || "",
            district: item.District || "",
            dealershipName: item["Dealership name"] || "",
          }));
          setExcelData(refineJson);

          // Create a map to track the occurrences of each email
          const emailCountMap = new Map<string, number>();

          // Count the occurrences of each email
          refineJson.forEach((item) => {
            emailCountMap.set(
              item.email,
              (emailCountMap.get(item.email) || 0) + 1
            );
          });

          // Filter and refine data with selected and duplicate flags
          const filteredData = refineJson.map(
            (item: ExcelDataType, index: number) => {
              const isDuplicate = item.email
                ? emailCountMap.get(item.email)! > 1
                : false;
              let selected = true;

              if (isDuplicate) {
                // Check if it's the first occurrence of the duplicate
                const occurrencesBefore = refineJson
                  .slice(0, index)
                  .filter((i) => i.email === item.email).length;

                // Only the first occurrence should be selected
                selected = occurrencesBefore === 0;
              }

              return {
                selected,
                duplicate: isDuplicate,
                clockedIn: "",
                ...item,
              };
            }
          );

          setExcelDataRefined(filteredData);
        }
      };

      reader.readAsBinaryString(selectedFiles);
    }
  }, [selectedFiles]);

  const SubmitData = async () => {
    if (excelDataRefined) {
      setLoading(true);
      await UseAddParticipants()
        .addParticipants(
          excelDataRefined
            .filter((item) => item.selected)
            .map(({ selected, duplicate, id, ...rest }) => rest)
        )
        .then((res) => {
          if (res.success) {
            setExcelDataRefined(undefined);
            setSelectedFile(undefined);
          }
          setOpen(true);
          setLoading(false);
        });
    }
  };

  return (
    <DashboardLayout>
      {loading && <LoadingState />}
      <MyModal isOpen={open} setIsOpen={setOpen}>
        <div className="text-center">
          <div className="py-4">
            <p>Your data has been successfully imported.</p>
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => {
                navigate(`/dashboard/participants`);
                setOpen(false);
              }}
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600"
            >
              Close
            </button>
          </div>
        </div>
      </MyModal>
      <div className="pt-10">
        <CustomBreadcrumb
          heading="Import from Excel"
          links={[
            { name: "Dashboard", path: "/dashboard" },
            { name: "Import", path: "/dashboard/import" },
          ]}
        />
        <div className="flex justify-between pt-5">
          <SelectFile onChange={selectOnchange} />
          <button
            onClick={SubmitData}
            disabled={!excelDataRefined}
            type="button"
            className="disabled:bg-gray-400 text-white h-12 bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-md text-sm px-5 py-2 text-center me-2 mb-2"
          >
            SUBMIT
          </button>
        </div>
        <div className="py-3">
          <ExcelDataTable
            data={excelDataRefined}
            setExcelDataRefined={setExcelDataRefined}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Index;
