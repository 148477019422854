import { RefinedExcelDataType } from "../../types/common";
import TablePagination from "./Pagination";
import UseTable from "./useTable";

const ExcelDataTable = ({
  data,
  setExcelDataRefined,
}: {
  data: RefinedExcelDataType[] | undefined;
  setExcelDataRefined: React.Dispatch<
    React.SetStateAction<RefinedExcelDataType[] | undefined>
  >;
}) => {
  const { page, rowsPerPage, handlePageChange, handleRowsPerPage } = UseTable();

  const userCheckboxHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const updatedData = data?.map((item, index) => {
      if (item.email === e.target.defaultValue) {
        return {
          ...item,
          selected: item.id === id && !item.selected,
        };
      }
      return item;
    });
    setExcelDataRefined(updatedData);
  };

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="p-4">
                {/* <div className="flex items-center">
                  <input
                    onChange={checkAllHandler}
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div> */}
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Dealership Name
              </th>
              <th scope="col" className="px-6 py-3">
                District
              </th>
              <th scope="col" className="px-6 py-3">
                Phone
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((item, index) => (
                <tr
                  key={index}
                  className={`group ${
                    item.duplicate ? "bg-orange-400" : "bg-white"
                  } border-b hover:bg-gray-50 `}
                >
                  <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input
                        value={item.email}
                        onChange={(e) => {
                          userCheckboxHandler(e, item.id);
                        }}
                        checked={item.selected}
                        id="checkbox-table-search-1"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label
                        htmlFor="checkbox-table-search-1"
                        className="sr-only"
                      >
                        checkbox
                      </label>
                    </div>
                  </td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td
                    className={`px-6 py-4 ${
                      item.duplicate ? "text-white font-bold" : ""
                    } group-hover:text-gray-950`}
                  >
                    {item.email}
                  </td>
                  <td
                    className={`px-6 py-4 ${
                      item.duplicate ? "text-white font-bold" : ""
                    } group-hover:text-gray-950`}
                  >
                    {item.dealershipName}
                  </td>
                  <td
                    className={`px-6 py-4 ${
                      item.duplicate ? "text-white font-bold" : ""
                    } group-hover:text-gray-950`}
                  >
                    {item.district}
                  </td>

                  <td
                    className={`px-6 py-4 ${
                      item.duplicate ? "text-white font-bold" : ""
                    } group-hover:text-gray-950`}
                  >
                    {item.phone}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <TablePagination
          count={data?.length || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPage={handleRowsPerPage}
        />
      </div>
    </div>
  );
};

export default ExcelDataTable;
