import moment from "moment";
import { ParticipantsList } from "../../../types/common";
import TablePagination from "../Pagination";
import UseTable from "../useTable";
import { useState } from "react";
import MyModal from "../../Modal";
import axios from "axios";

const ParticipantsDataTable = ({
  data,
}: {
  data: ParticipantsList[] | undefined;
  setExcelDataRefined?: React.Dispatch<
    React.SetStateAction<ParticipantsList[] | undefined>
  >;
}) => {
  const { page, rowsPerPage, handlePageChange, handleRowsPerPage } = UseTable();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<string | undefined>();

  // console.log(data);

  const getClockinDate = (date: any) => {
    if (!date) return "Not Clocked In";
    return moment(new Date(date.seconds * 1000)).format("YYYY-MM-DD hh:mm");
  };

  const downloadSingleImage = async (participant: ParticipantsList) => {
    try {
      if (participant.invitationImageUrl) {
        const response = await axios.get(participant.invitationImageUrl, {
          responseType: "blob",
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${participant.code + " " + participant.name}.png`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    } catch (error) {
      console.error(`Error downloading image for ${participant.name}:`, error);
    }
  };

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Dealership Name
              </th>
              <th scope="col" className="px-6 py-3">
                District
              </th>
              <th scope="col" className="px-6 py-3">
                Phone
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((item, index) => (
                <tr key={index} className={`group border-b hover:bg-gray-50 `}>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td className={`px-6 py-4 group-hover:text-gray-950`}>
                    {item.email}
                  </td>
                  <td className={`px-6 py-4 group-hover:text-gray-950`}>
                    {item.dealershipName}
                  </td>
                  <td className={`px-6 py-4 group-hover:text-gray-950`}>
                    {item.district}
                  </td>
                  <td className={`px-6 py-4 group-hover:text-gray-950`}>
                    {item.phone}
                  </td>

                  <td className="px-6 py-4 flex space-x-2">
                    {item.invitationImageUrl && (
                      <div>
                        <button
                          className="bg-green-300 px-2 py-1 rounded-md text-green-900"
                          onClick={() => {
                            setImage(item.invitationImageUrl);
                            setOpen(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                        </button>
                        <MyModal isOpen={open} setIsOpen={setOpen}>
                          <div className="flex justify-center items-center">
                            {image && (
                              <img
                                src={image}
                                alt="invitation"
                                className="h-[600px] w-[300px]"
                              />
                            )}
                          </div>
                        </MyModal>
                      </div>
                    )}
                    {item.invitationImageUrl === "" && (
                      <div className="bg-orange-100 text-orange-500 w-fit px-2 py-1 rounded-lg">
                        Not Yet Generated
                      </div>
                    )}
                    {item.invited && (
                      <div className="bg-green-100 text-green-800 w-fit px-2 py-1 rounded-lg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                          />
                        </svg>
                      </div>
                    )}
                    {item.invitationImageUrl && (
                      <button
                        onClick={() => downloadSingleImage(item)}
                        className="bg-blue-300 px-2 py-1 rounded-md text-blue-900"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                          />
                        </svg>
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <TablePagination
          count={data?.length || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPage={handleRowsPerPage}
        />
      </div>
    </div>
  );
};

export default ParticipantsDataTable;
