import React, { useEffect, useState } from "react";
import { PaginationProps, RefinedExcelDataType } from "../../types/common";
import { TablePaginationOptions } from "../../constants/table";

const TablePagination = (props: PaginationProps) => {
  const totalPages = Math.ceil(props.count / props.rowsPerPage);
  const currentPage = props.page;

  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const maxPagesToShow = 7;
  let pagesToDisplay = [];

  if (totalPages <= maxPagesToShow) {
    // If total pages are 7 or less, show all pages
    pagesToDisplay = pageNumbers;
  } else {
    // If current page is near the beginning
    if (currentPage <= 4) {
      pagesToDisplay = [...pageNumbers.slice(0, 6), "...", totalPages];
    }
    // If current page is near the end
    else if (currentPage >= totalPages - 3) {
      pagesToDisplay = [1, "...", ...pageNumbers.slice(totalPages - 6)];
    }
    // If current page is in the middle
    else {
      pagesToDisplay = [
        currentPage - 3,
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2,
        "...",
        totalPages,
      ];
    }
  }

  const handleRowsPerPageChange = (rows: number) => {
    setRowsPerPage(rows);
    props.handleRowsPerPage(rows);
  };

  useEffect(() => {
    let tempPageNumbers: number[] = [];
    if (totalPages) {
      for (let i = 1; i <= totalPages; i++) {
        tempPageNumbers.push(i);
      }
      setPageNumbers(tempPageNumbers);
    }
  }, [totalPages]);

  const handleClickPage = (page: number) => {
    props.handlePageChange(page);
  };

  const handleClickPrevious = () => {
    if (currentPage > 1) {
      props.handlePageChange(currentPage - 1);
    }
  };

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      props.handlePageChange(currentPage + 1);
    }
  };

  const RowsPerPageDropdown = ({
    value,
    onChange,
  }: {
    value: number;
    onChange: (value: number) => void;
  }) => {
    return (
      <div className="inline-block text-left">
        <label htmlFor="rowsPerPageDropdown" className="sr-only">
          Rows per page
        </label>
        <select
          id="rowsPerPageDropdown"
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          {TablePaginationOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <div>
      <nav
        className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
          Showing
          <span className="font-medium">
            {" "}
            {(currentPage - 1) * props.rowsPerPage + 1}{" "}
          </span>
          to
          <span className="font-medium">
            {" "}
            {props.count < props.rowsPerPage
              ? props.count
              : (currentPage - 1) * props.rowsPerPage + props.rowsPerPage}{" "}
          </span>{" "}
          of <span className="font-semibold text-gray-900">{props.count}</span>
        </span>
        <div className="pb-2">
          <RowsPerPageDropdown
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          />
        </div>
        <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
          <li>
            <div
              onClick={handleClickPrevious}
              className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
            >
              Previous
            </div>
          </li>
          {pagesToDisplay.map((number, idx) => (
            <li key={idx}>
              <div
                onClick={() =>
                  typeof number === "number" && handleClickPage(number)
                }
                className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                  currentPage === number
                    ? "text-gray-700 bg-gray-200"
                    : "text-gray-500"
                } ${number === "..." ? "cursor-default" : ""}`}
              >
                {number}
              </div>
            </li>
          ))}

          <li>
            <a
              onClick={handleClickNext}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
            >
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TablePagination;
