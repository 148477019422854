import {
  addDoc,
  collection,
  writeBatch,
  doc,
  getDocs,
  updateDoc,
  query,
  orderBy,
  getDoc,
} from "@firebase/firestore";
import { db } from "../config/firebase-config";
import { FirebaseRecordType } from "../types/common";
import { ref, uploadBytesResumable } from "@firebase/storage";
import { storage } from "../config/firebase-config";
import { getDownloadURL } from "firebase/storage";

export const UseAddParticipants = () => {
  const batch = writeBatch(db);
  const addParticipants = async (data: FirebaseRecordType[]) => {
    try {
      const event1CollectionRef = collection(db, "event1");

      data.forEach(async (item) => {
        batch.set(doc(event1CollectionRef), item);
      });

      await batch.commit();
      return { success: true };
    } catch (e) {
      console.error(e);
      return { success: false, data: e };
    }
  };

  return { addParticipants };
};

export const getParticipants = async () => {
  try {
    const event1CollectionRef = collection(db, "event1");
    const q = query(event1CollectionRef, orderBy("code", "asc"));
    const snapshot = await getDocs(q);
    const participants = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return { success: true, data: participants };
  } catch (e) {
    console.error(e);
    return { success: false, data: e };
  }
};

export const getParticipantsAttendance = async () => {
  try {
    const event1CollectionRef = collection(db, "event1");
    const q = query(event1CollectionRef, orderBy("code", "asc"));
    const snapshot = await getDocs(q);
    const participants = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return { success: true, data: participants };
  } catch (e) {
    console.error(e);
    return { success: false, data: e };
  }
};

export const updateParticipant = async (id: string) => {
  try {
    const event1CollectionRef = collection(db, "event1");
    const participantDocRef = doc(event1CollectionRef, id);

    const currentDate = new Date();
    const updatedData = {
      clockedIn: currentDate,
    };

    await updateDoc(participantDocRef, updatedData);
    const updatedDoc = await getDoc(participantDocRef);

    if (updatedDoc.exists()) {
      return { success: true, data: updatedDoc.data() };
    } else {
      return { success: false, data: "Document does not exist after update." };
    }
  } catch (e) {
    console.error(e);
    return { success: false, data: e };
  }
};

export const getParticipantById = async (id: string) => {
  try {
    const event1CollectionRef = collection(db, "event1");
    const participantDocRef = doc(event1CollectionRef, id);
    const docSnap = await getDoc(participantDocRef);
    if (docSnap.exists()) {
      if (!docSnap.data().clockedIn) {
        return await updateParticipant(id)
          .then((res) => {
            return { success: true, data: res.data, isClockedIn: false };
          })
          .catch((e) => {
            return { success: false, data: e, isClockedIn: false };
          });
      } else {
        return { success: true, data: docSnap.data(), isClockedIn: true };
      }
    } else {
      return { success: false, data: "No such document!", isClockedIn: false };
    }
  } catch (e) {
    console.error(e);
    return { success: false, data: e, isClockedIn: false };
  }
};

export const uploadBlobToStorage = async (
  blobUrl: any[],
  onProgress?: (progress: string, index: number) => void
) => {
  const batch = writeBatch(db);
  try {
    const storageRef = ref(storage, "event1");

    const uploadPromises = blobUrl.map(async (url, index) => {
      const response = await fetch(url.snapshotUrl);

      const blob = await response.blob();

      const fileRef = ref(storageRef, url.id);
      const uploadTask = uploadBytesResumable(fileRef, blob);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress && onProgress(progress.toFixed(2), index);
      });
      await uploadTask;

      return { snapshot: uploadTask.snapshot }; // Add this line to return the snapshot
    });

    const downloadUrls = await Promise.all(
      uploadPromises.map(async (uploadPromise) => {
        const { snapshot } = await uploadPromise; // Destructure the snapshot from uploadPromise
        const downloadUrl = await getDownloadURL(snapshot.ref);
        return { id: snapshot.ref.name, url: downloadUrl };
      })
    );

    const event1CollectionRef = collection(db, "event1");

    downloadUrls.forEach(async (item) => {
      const participantDocRef = doc(event1CollectionRef, item.id);
      const updatedData = {
        invitationImageUrl: item.url,
      };
      await updateDoc(participantDocRef, updatedData);
      batch.update(participantDocRef, updatedData);
    });

    return { success: true, data: downloadUrls };
  } catch (e) {
    console.error(e);
    return { success: false, data: e };
  }
};

export const updateParticipantMailStatus = async (id: string) => {
  try {
    const event1CollectionRef = collection(db, "event1");
    const participantDocRef = doc(event1CollectionRef, id);
    const updatedData = {
      invited: true,
    };
    await updateDoc(participantDocRef, updatedData);
    return { success: true };
  } catch (e) {
    console.error(e);
    return { success: false, data: e };
  }
};
