import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase-config";
import { useState } from "react";
import { useNavigate } from "react-router";
import LoadingState from "../../Components/Loading";

const Index = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState<string | undefined>("");
  const [password, setPassword] = useState<string | undefined>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email && password) {
      setLoading(true);
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          navigate(`/dashboard`);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div>
      {loading && <LoadingState />}
      <div className="bg-[#070738]">
        <div className="flex justify-center container mx-auto my-auto w-screen h-screen items-center flex-col">
          <div className="text-slate-100 items-center">
            <svg
              className="w-10 h-10 mx-auto pb-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              ></path>
            </svg>
            <div className="text-center pb-3">Welcome back!</div>
          </div>

          <form
            className="w-full md:w-3/4  lg:w-1/2 flex flex-col items-center bg-slate-50 rounded-md pt-12"
            onSubmit={onLogin}
          >
            <div className="w-3/4 mb-6">
              <input
                type="email"
                name="email"
                id="email"
                className="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 hover:ring-gray-600 outline-slate-500 border-solid border-2 border-slate-300"
                placeholder="Email adress"
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
              />
            </div>

            <div className="w-3/4 mb-6">
              <input
                type="password"
                name="password"
                id="password"
                className="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 hover:ring-gray-600 outline-slate-500  border-solid border-2 border-slate-300"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>

            <div className="w-3/4 mb-12">
              <button
                type="submit"
                className="py-4 bg-[#070738] w-full rounded text-blue-50 font-bold hover:bg-[#070938d8]"
              >
                LOGIN
              </button>
            </div>
          </form>
          <div className="flex justify-center container mx-auto mt-6 text-slate-100 text-sm">
            <div className="flex flex-col sm:flex-row  justify-between md:w-1/2 items-center">
              <div className="flex">Forgot your password</div>
              <div className="flex ">Don't have an account? Get Started</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
