import React from "react";
import { DashboardLayout } from "../../Layouts/Dashboard/Index";
import CustomBreadcrumb from "../../Components/custom-breadcrumb/CustmBreadcrumb";

const Index = () => {
  return (
    <DashboardLayout>
      <div className="pt-10">
        <CustomBreadcrumb
          heading="Dashboard"
          links={[{ name: "Dashboard", path: "/dashboard" }]}
        />
      </div>
    </DashboardLayout>
  );
};

export default Index;
