import moment from "moment";
import { ParticipantsList } from "../../../types/common";
import TablePagination from "../Pagination";
import UseTable from "../useTable";
import { useState } from "react";
import MyModal from "../../Modal";
import axios from "axios";

const AttendanceDataTable = ({
  data,
}: {
  data: ParticipantsList[] | undefined;
  setExcelDataRefined?: React.Dispatch<
    React.SetStateAction<ParticipantsList[] | undefined>
  >;
}) => {
  const { page, rowsPerPage, handlePageChange, handleRowsPerPage } = UseTable();

  const getClockinDate = (date: any) => {
    if (!date) return "Not Clocked In";
    return moment(new Date(date.seconds * 1000)).format("YYYY-MM-DD hh:mmA");
  };

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Dealership Name
              </th>
              <th scope="col" className="px-6 py-3">
                District
              </th>
              <th scope="col" className="px-6 py-3">
                Phone
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              ?.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              .map((item, index) => (
                <tr key={index} className={`group border-b hover:bg-gray-50 `}>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {item.name}
                  </th>
                  <td className={`px-6 py-4 group-hover:text-gray-950`}>
                    {item.email}
                  </td>
                  <td className={`px-6 py-4 group-hover:text-gray-950`}>
                    {item.dealershipName}
                  </td>
                  <td className={`px-6 py-4 group-hover:text-gray-950`}>
                    {item.district}
                  </td>
                  <td className={`px-6 py-4 group-hover:text-gray-950`}>
                    {item.phone}
                  </td>

                  <td className="px-6 py-4 flex space-x-2">
                    {item.clockedIn ? (
                      <div className="flex items-center space-x-1 bg-green-100 text-green-600 py-1 px-2 rounded-md">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                        <div> {getClockinDate(item.clockedIn)}</div>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-1 bg-yellow-100 text-yellow-600 py-1 px-2 rounded-md">
                        Not Clockedin
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <TablePagination
          count={data?.length || 0}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPage={handleRowsPerPage}
        />
      </div>
    </div>
  );
};

export default AttendanceDataTable;
