type Props = {
  links: Link[]
  heading?: string
}

type Link = { name: string; path?: string }

export default function CustomBreadcrumb({ links, heading }: Props) {
  return (
    <div>
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          {heading && (
            <h3 className="text-2xl font-semibold text-gray-700">{heading}</h3>
          )}
          <nav className="flex py-3 text-gray-700" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-3">
              <li className="inline-flex items-center">
                <a
                  className="inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-900"
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                </a>
              </li>
              {links.map((link: any) => (
                <li key={link.name}>
                  <div className="flex items-center">
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <a
                      href={link.path}
                      className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-900 md:ml-2"
                    >
                      {link.name}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
    </div>
  )
}
