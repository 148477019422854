import { useContext } from "react";
import { Context } from "../context/AuthContext";
import { Navigate } from "react-router";
import { ReactNode } from "react";

export const Protected = ({ children }: { children: any }) => {
  const { user } = useContext(Context);

  if (!user) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
};
