import React from "react";
import { NavigationItem } from "../../../types/Navigation";

const Navitem = ({ icon, title, href, selected }: NavigationItem) => {
  return (
    <li className="relative group">
      <a
        href={href}
        className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-white ${
          selected ? "bg-white text-black z-20" : "text-white"
        }  hover:text-[#070738] rounded-l-3xl pr-6`}
      >
        <span className="inline-flex justify-center items-center ml-4">
          {icon}
        </span>
        <span className="ml-2 text-sm tracking-wide truncate hidden sm:block">{title}</span>
        <div className={`group-hover:block hidden`}>
          <div>
            <div className="bg-[#070738] rounded-full h-10 w-10 absolute -top-10 right-0 z-10"></div>
            <div className="bg-white rounded-full h-10 w-10 absolute -top-5 -right-5"></div>
          </div>
          <div>
            <div className="bg-[#070738] rounded-full h-10 w-10 absolute -bottom-10 right-0 z-10"></div>
            <div className="bg-white rounded-full h-10 w-10 absolute -bottom-5 -right-5"></div>
          </div>
        </div>
      </a>
    </li>
  );
};

export default Navitem;
